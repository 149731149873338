.flippable-cards {
  &-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    .flippable-card {
      position: relative;
      overflow: hidden;
      cursor: pointer;

      &__side {
        width: 100%;
        height: 100%;
        overflow: hidden;
        transition: all var(--emu-common-other-time-duration-instant) linear;
        position: relative;

        // author style, need important to override any other styles
        @include aem-editor-view {
          position: static !important;
          opacity: 1 !important;
        }

        &--absolute {
          position: absolute;
        }

        &--back {
          display: flex;
          flex-direction: column;
          opacity: 0;
          left: 0;
          top: 0;
          pointer-events: none;
          position: absolute;
        }
      }

      @mixin flippableCardActive {
        .flippable-card__side--back {
          opacity: 1;
          pointer-events: initial;
        }

        .flippable-card__side--front {
          opacity: 0;
          pointer-events: none;
        }
      }

      @media (pointer: fine) {
        &:hover {
          @include flippableCardActive;
        }
      }

      @media (pointer: coarse) {
        &.flippable-card--click-active {
          @include flippableCardActive;
        }
      }
    }
  }
}
