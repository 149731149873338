.linkedin-container {
  height: 417px;

  @include mq('large') {
    border: var(--emu-common-border-width-thin) solid
      var(--emu-common-colors-medium-brown-500);
    margin-bottom: 40px;
    height: 590px;
  }
}
