#error-page {
  .error {
    &__page {
      margin-top: 40px;
      margin-right: auto;
      margin-bottom: 40px;
      margin-left: auto;

      @include mq('large') {
        width: 70%; // to make it look good
      }

      .button {
        text-align: center;

        a.error-page__btn {
          color: var(--emu-common-colors-white);
          background-color: var(--emu-common-colors-light-brown-500);
          font-size: var(--emu-common-font-sizes-narrow-xl);
          padding-top: 12px;
          padding-bottom: 12px;
          padding-left: 14px;
          padding-right: 14px;
          text-decoration: none;
          margin-bottom: 20px;
          margin-top: 20px;

          @include mq('large') {
            margin-bottom: 30px;
            margin-top: 30px;
          }
        }
      }
    }

    &__title .cmp-title__text {
      font-size: var(--emu-semantic-font-sizes-narrow-xl);
      font-weight: var(--emu-common-font-weight-light);
      margin-bottom: 20px;

      @include mq('large') {
        font-size: var(--emu-semantic-font-sizes-wide-xl);
        line-height: var(--emu-common-line-heights-wide-xl);
        margin-bottom: 40px;
      }
    }

    &__text a {
      color: var(--emu-semantic-colors-tertiary-50);
    }
  }
}
