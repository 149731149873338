footer {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
  padding-bottom: 34px;

  @include mq('large') {
    padding-left: 76px;
    padding-right: 76px;
    padding-top: 89px;
    padding-bottom: 69px;
  }

  &.footer {
    background-color: var(--emu-common-colors-beige-500);

    .upper_section {
      max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
      margin: auto;
    }
  }

  .footer {
    &__top-container {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: var(--emu-common-spacing-large); // to match the design.
      justify-content: space-between;
      margin-bottom: 20px;

      @include mq('large') {
        align-items: center;
        flex-direction: row;
        margin-bottom: 17px;
      }

      &-logo {
        img {
          width: 100%;
          max-width: 157px;

          @include mq('large') {
            max-width: 231px;
          }
        }
      }

      .aaaem-text {
        p {
          text-align: left;
          font-size: 16px;
          line-height: 1.3;
          font-family: var(--emu-common-font-families-sans);
          padding-right: 20px;

          @include mq('large') {
            font-size: 18px;
            text-align: right;
            padding-right: var(--emu-common-spacing-none);
          }

          a {
            color: inherit;
            text-decoration: none;

            &:hover,
            &:active,
            &:focus {
              color: inherit;
              text-decoration: none;
            }
          }
        }
      }
    }

    &__bottom-container {
      display: flex;
      flex-direction: column-reverse;
      align-items: start;
      justify-content: space-between;
      gap: 20px; // to match the design.

      @include mq('large') {
        align-items: end;
        flex-direction: row;
      }

      > .container {
        width: 100%;
      }

      ul {
        list-style-type: none;
        display: flex;
        flex-wrap: nowrap;
        column-gap: 98px; // to match the design.
        padding: var(--emu-common-spacing-none);
        margin: var(--emu-common-spacing-none);
        flex-direction: column;
        font-size: 16px;
        line-height: 32px;

        @include mq('large') {
          flex-direction: row;
          flex-wrap: wrap;
          font-size: 18px;
          justify-content: end;
          row-gap: 10px;
        }

        li {
          a {
            text-decoration: none;
            color: inherit;
            font-family: var(--emu-common-font-families-sans);
            letter-spacing: -0.25px; //to match design
          }

          img {
            max-width: 30px; // to match the design.
            display: inline-block;
            margin-left: 10px; // to match the design.
          }
        }
      }

      &-social {
        display: flex;
        align-items: center;
        gap: 9px;
        padding-top: var(--emu-common-spacing-xs);
        padding-bottom: var(--emu-common-spacing-xs);

        @include mq('large') {
          padding-top: var(--emu-common-spacing-none);
          padding-bottom: var(--emu-common-spacing-none);
        }

        img {
          min-width: 25px; // to match design.
        }

        .aaaem-text {
          p {
            font-size: 16px;
            line-height: 1.3; //to match design
            font-family: var(--emu-common-font-families-sans);

            @include mq('large') {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
