.prm-masonry-grid {
  &__wrapper {
    flex-direction: column;
    padding: var(--emu-common-spacing-none);

    @include mq('large') {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-row-gap: 30px;
      grid-column-gap: 30px;
      margin-bottom: 30px;
    }

    @include aem-editor-view {
      display: block !important;
    }

    > .container {
      margin-bottom: 10px;

      @include mq('large') {
        margin-bottom: var(--emu-common-spacing-none);
      }

      .flippable-card__side {
        @include mq('large') {
          height: 448px; // to match the design.
        }
      }

      // title of the whole section
      &:first-child {
        @include mq('large') {
          grid-row: 1;
          grid-column: 1 / 4;
        }

        .flippable-card__side {
          @include mq('large') {
            height: auto;
          }

          .cmp-title__text {
            br {
              @include mq('large') {
                display: none;
              }
            }
          }
        }
      }

      // 2 small columns on the left
      // multiple modes and versatile tiles
      &:nth-child(2),
      &:nth-child(3) {
        .flippable-card__side--back {
          @include mq('large') {
            padding-top: 45px;
          }

          @include mq('x-large') {
            padding-top: 86px;
          }
        }
      }

      // versatile tile
      &:nth-child(3) {
        .cmp-title__text {
          @include mq('x-large') {
            padding-left: 52px;
            padding-right: 52px;
          }
        }

        .aaaem-text li {
          @include mq('x-large') {
            margin-bottom: var(--emu-common-spacing-none);
          }
        }
      }

      // personalized support tile
      &:nth-child(4) {
        @include mq('large') {
          grid-row: 2 / 4;
          grid-column: 3;
        }

        .flippable-card__side {
          @include mq('large') {
            height: 925px; // to match the design.
          }

          &--back {
            @include mq('large') {
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
          }
        }
      }

      // top notch medical tile
      &:nth-child(5) {
        @include mq('large') {
          grid-column: 1 / 3;
        }

        .cmp-title__text {
          br {
            display: none;

            @include mq('large') {
              display: block;
            }
          }
        }

        .flippable-card__side--back {
          @include mq('large') {
            justify-content: center;
          }

          @include mq('x-large') {
            justify-content: start;
            padding-top: 104px;
          }
        }
      }
    }

    @mixin flippableCardHover {
      .flippable-card__side--front {
        position: absolute;

        @include mq('large', 'max-width') {
          display: none;
          transition: none;
        }
      }

      .flippable-card__side--back {
        position: relative;

        @include mq('large', 'max-width') {
          display: block;
          transition: none;
        }
      }
    }

    .flippable-card {
      // Overriding main flippable card styles to match the cards in services section

      @media (pointer: coarse) {
        &--click-active {
          @include flippableCardHover;
        }
      }

      @media (pointer: fine) {
        &:hover {
          @include flippableCardHover;
        }
      }

      &__side {
        // author style, need important to override any other styles
        @include aem-editor-view {
          position: static !important;
        }

        &--front {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-top: 23px;
          padding-bottom: 23px;
          position: relative;

          @include mq('large') {
            padding-top: var(--emu-common-spacing-large);
            padding-bottom: var(--emu-common-spacing-large);
          }

          .cmp-title__text {
            padding-left: 35px;
            padding-right: 35px;
          }
        }

        &--back {
          padding-top: 20px;
          padding-right: var(--emu-common-spacing-large);
          padding-bottom: 20px;
          padding-left: var(--emu-common-spacing-large);
          position: absolute;

          @include mq('large', 'max-width') {
            display: none;
          }

          @include mq('x-large') {
            padding-left: 42px;
            padding-right: 42px;
          }

          .aaaem-text {
            ul {
              margin: var(--emu-common-spacing-none);
              padding: var(--emu-common-spacing-none);
            }

            p,
            li {
              font-size: var(--emu-semantic-font-sizes-narrow-small);
              line-height: var(--emu-semantic-line-heights-narrow-medium);
              margin-bottom: 9px;

              @include mq('large') {
                font-size: var(
                  --emu-common-font-sizes-wide-medium
                ); // decreased font size so content does not get cropped
              }

              @include mq('x-large') {
                font-size: var(--emu-semantic-font-sizes-wide-small);
              }
            }

            li {
              list-style: none;
              font-family: var(
                --emu-semantic-font-families-neue-haas-unica-light
              );
              font-weight: var(--emu-common-font-weight-light);
            }

            b {
              font-family: var(
                --emu-semantic-font-families-neue-haas-unica-bold
              );
            }
          }
        }
      }
    }
  }

  &__content-list {
    @include mq('large') {
      display: flex;
      gap: 63px;
    }

    ul {
      flex: 1;

      &:first-child {
        flex: 1.2; // to match the design.
      }

      @include mq('x-large') {
        max-width: 328px; // to match the design.
      }
    }
  }
}
