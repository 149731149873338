.contact-us-card {
  position: fixed;
  top: 220px;
  right: 0;
  z-index: var(--emu-common-other-z-index-overlay);
  width: 232px; // as per the design
  transform: translateX(calc(100% - 24px)); // 24 is the header height
  transition: all var(--emu-common-other-time-duration-instant) ease-in;
  color: var(--emu-common-colors-black);

  @include mq('large') {
    width: 445px;
    transform: translateX(calc(100% - 43px)); // 43 is the header height
    top: 300px;
  }

  &.js-toggle-on {
    transform: translateX(0);
    transition-timing-function: ease-out;
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;

    &,
    &:active,
    &:focus,
    &:hover {
      outline: none;
      -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
    }

    @include mq('large') {
      top: 30px;
      right: 30px;
    }

    span {
      display: none;
    }

    img {
      width: var(--emu-common-sizing-xs);

      @include mq('large') {
        width: 13px;
      }
    }
  }

  .aaaem-card {
    &__title-wrapper {
      width: 24px; // as per the design
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      cursor: pointer;

      @include mq('large') {
        width: 43px;
      }

      h3 {
        font-size: var(--emu-common-font-sizes-narrow-medium);
        font-weight: var(--emu-common-font-weight-light);
        line-height: 24px; // 48 as per design, but does not fit well
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(-90deg);
        white-space: nowrap;

        @include mq('large') {
          font-size: 24px;
          line-height: 43px; // 48 as per design, but does not fit well
        }
      }
    }

    &__content-wrapper {
      p {
        font-family: var(--emu-semantic-font-families-beatrice-regular);
        font-size: 13px;
        line-height: 22px;

        @include mq('large') {
          font-size: 23px;
          line-height: var(--emu-semantic-line-heights-wide-xl);
        }

        b {
          font-weight: var(--emu-semantic-font-weight-600);
          font-family: var(--emu-semantic-font-families-beatrice-semibold);
        }

        a {
          &,
          &:hover,
          &:focus,
          &:active {
            outline: none;
            color: inherit;
            text-decoration: none;
          }
        }
      }

      .button {
        position: static;
      }
    }

    &__footer {
      display: none;
    }
  }
}
