.prm__carousel-container {
  .aaaem-carousel__indicators {
    gap: 23px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;

    @include mq('large') {
      bottom: 19px;
    }

    .aaaem-carousel__indicator {
      background-color: var(--emu-common-colors-light-brown-500);
      border-color: var(--emu-common-colors-medium-brown-500);
      width: 12px;
      height: 12px;

      &.tns-nav-active {
        background-color: var(--emu-common-colors-medium-brown-500);
        border-color: var(--emu-common-colors-medium-brown-500);
      }
    }
  }
}
