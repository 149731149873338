header.experiencefragment {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: var(--emu-common-other-z-index-header);
}

main {
  @include mq('large') {
    margin-top: 119px;
  }
}

.header {
  position: relative;

  &:is(header) {
    width: 100%;
    transition: all var(--emu-common-other-time-duration-instant) linear;
  }

  .header__menu-open {
    display: block;
    max-width: 31px;
    max-height: 31px;
    border: none;

    @include mq('large') {
      display: none;
    }
  }

  .header__menu-close {
    display: none;
    border: none;
    max-width: 20px;
    max-height: 20px;
    padding-right: 24px;

    @include mq('large') {
      display: none;
    }
  }

  .header__nav-container {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10px;
    padding-left: 11px;
    position: relative;
    max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
    margin: auto;
    transition: all var(--emu-common-other-time-transition-short) linear;

    @include mq('large') {
      padding-top: 11px;
      padding-bottom: 11px;
      padding-right: 46px;
      padding-left: 46px;
    }

    .image {
      width: 100%;

      @include mq('large') {
        width: 30%;
      }
    }

    &-aalogo {
      max-width: 100px;

      @include mq('large') {
        max-width: 138px;
      }
    }

    .navigationlist {
      width: 100%;

      @include mq('large') {
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .emu-navigation__content-wrapper {
        position: fixed;
        top: 84px;
        left: 0;
        opacity: 0.5;
        transform: translate(100%);
        transition: all var(--emu-common-other-time-duration-instant) ease-in;
        z-index: var(--emu-common-other-z-index-layer);

        @include mq('large') {
          max-width: 883px;
          position: static;
          opacity: 1;
          transform: none;
        }
      }
    }

    .emu-navigation {
      background-color: var(--emu-common-colors-medium-brown-500);

      ul {
        justify-content: space-between;
        flex-direction: column;
        color: var(--emu-common-colors-white);
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;

        @include mq('large') {
          flex-direction: row;
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
          padding-bottom: var(--emu-common-spacing-none);
          justify-content: flex-end;
          column-gap: 40px;
        }

        li {
          padding-top: 18px;
          padding-bottom: 18px;
          padding-left: 20px;
          padding-right: 20px;
          font-size: 16px;
          line-height: var(--emu-semantic-line-heights-narrow-large);
          font-weight: var(--emu-common-font-weight-regular);
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-family: var(--emu-semantic-font-families-beatrice-regular);
          cursor: pointer;

          @include mq('large') {
            padding-top: var(--emu-common-spacing-none);
            padding-bottom: var(--emu-common-spacing-none);
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
            font-size: 18px;
          }

          &::after {
            content: '';
            background-image: url('../../assets/images/arrow-right-white.png');
            display: inline-block;
            width: 10px;
            height: 16px;
            background-repeat: no-repeat;
            background-size: contain;

            @include mq('large') {
              display: none;
            }
          }

          &:hover,
          &:active {
            background-color: var(--emu-common-colors-white);
            color: var(--emu-common-colors-medium-brown-500);
            font-weight: var(--emu-semantic-font-weight-500);

            @include mq('large') {
              background-color: inherit;
              color: inherit;
              font-weight: var(--emu-common-font-weight-light);
            }

            &::after {
              content: '';
              background-image: url('../../assets/images/arrow-right-brown.png');
              display: inline-block;
              width: 10px;
              height: var(--emu-common-sizing-small);
              background-repeat: no-repeat;
              background-size: contain;

              @include mq('large') {
                display: none;
              }
            }

            a {
              font-weight: var(--emu-semantic-font-weight-500);

              @include mq('large') {
                font-weight: var(--emu-common-font-weight-light);
              }
            }
          }

          a {
            all: unset;
            width: 100%;
          }
        }
      }
    }
  }

  &__logo-container-mobile {
    display: flex;
    background-color: var(--emu-common-colors-white);
    align-items: center;
    gap: 10px;
    padding-left: 19px;
    padding-right: 36px;
    padding-bottom: 7px;
    padding-top: 7px;
    position: relative;
    box-shadow: 0 4px 4px 0px rgba(202, 154, 142, 0.15);
    z-index: 1; // no specific variable for z-index 1

    @include mq('large') {
      display: none;
    }

    > .image {
      img {
        max-width: 44px;
      }
    }

    > .button {
      width: 100%;
    }
  }

  .header__logo-menu-button {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    &:active {
      outline-color: var(--emu-common-colors-transparent);
    }

    p {
      font-size: 8px;
      line-height: 10px;
      font-weight: var(--emu-common-font-weight-regular);
      text-align: left;
    }

    p:first-child {
      font-size: 16px;
      line-height: 24px;
      font-weight: var(--emu-common-font-weight-light);
      color: var(--emu-common-colors-dark-brown-500);
      font-family: var(--emu-semantic-font-families-beatrice-light);
    }

    img {
      transform: rotate(180deg);
      transition: var(--emu-common-other-time-transition-short) linear;
    }

    &.js-toggle-on {
      img {
        transform: rotate(360deg);
      }
    }
  }

  &__logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 23px;
    max-height: none;
    flex-wrap: wrap;

    @include mq('large') {
      justify-content: space-between;
      gap: 29px;
      max-height: 93px;
      flex-wrap: nowrap;
    }

    img {
      width: 100%;
      max-width: 80px;

      @include mq('large') {
        max-width: 130px;
      }
    }

    &-prm {
      img {
        max-width: 48px;
      }
    }
  }

  &__wrapper {
    position: relative;

    > .container:first-child {
      background-color: var(--emu-common-colors-medium-brown-500);
    }

    > .button {
      position: absolute;
      top: 20px;
      right: 10px;
    }

    &.js-toggle-on {
      .header__menu-open {
        display: none;
      }

      .header__menu-close {
        display: initial;

        @include mq('large') {
          display: none;
        }
      }

      .navigationlist .emu-navigation__content-wrapper {
        display: block;
        opacity: 1;
        transform: translate(0);

        @include mq('large') {
          position: static;
          transform: none;
        }
      }
    }
  }

  .header__scroll-position-dependent {
    margin-left: auto;
    margin-right: auto;
    transition: all 0.3s ease-out;
    z-index: var(--emu-common-other-z-index-behind);

    @include mq('large') {
      position: absolute;
      // Disable the interactive elements
      visibility: hidden;
      top: -100%;
      left: 0;
      right: 0;
    }

    .header__logo-disclaimer-container {
      display: flex;
      flex-direction: column;
      padding-right: 24px;
      padding-left: 40px;
      padding-top: 20px;
      padding-bottom: 20px;
      background-color: var(--emu-common-colors-white);
      max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
      margin: auto;
      display: none;

      @include mq('375px', 'max-width') {
        padding-left: 5px;
        padding-right: 5px;
      }

      @include mq('large') {
        padding-right: 50px;
        padding-left: 50px;
        padding-top: 10px;
        padding-bottom: 2.5px;
        display: flex;
        justify-content: space-around;
      }

      @include mq('x-large') {
        justify-content: center;
      }

      &.js-toggle-on {
        display: block;
        position: absolute;
        width: 100%;

        @include mq('large') {
          position: static;
        }
      }

      .text {
        @include mq('large') {
          margin-top: 2.5px;
        }

        p {
          color: var(--emu-common-colors-medium-brown-500);
          font-size: 10px;
          line-height: 22px;
        }
      }
    }

    .aaaem-title {
      h1 {
        font-size: 16px;
        line-height: 34px;
        color: var(--emu-common-colors-white);
        font-family: var(--emu-semantic-font-families-beatrice-regular);
        padding-top: var(--emu-common-spacing-none);
        padding-bottom: var(--emu-common-spacing-none);
      }
    }
  }
}

body.hide-full-header {
  .header {
    &__nav-container {
      @include mq('large') {
        box-shadow: 0px 4px 5px 0px rgba(73, 39, 40, 0.15); // no color variable
      }
    }
  }
}

body:not(.hide-full-header) {
  .header__scroll-position-dependent {
    @include mq('large') {
      top: 100%;
      visibility: visible;
    }
  }
}
