.button {
  .aaaem-button {
    &__primary {
      &-filled {
        font-family: var(--emu-semantic-font-families-beatrice-regular);
        font-size: 18px;
        font-weight: var(---emu-semantic-font-weight-regular);
        line-height: var(--emu-semantic-line-heights-narrow-small);
        text-decoration-line: underline;
        align-items: baseline;

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-small);
          line-height: var(--emu-semantic-line-heights-wide-small);
        }

        svg {
          width: 20px; // to match the design.
          height: 20px; // to match the design.
          margin-left: 10px;

          @include mq('large') {
            width: 24px;
            height: 24px;
            margin-left: 17px;
          }
        }
      }
    }
  }
}
