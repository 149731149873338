// set visibility by device/screen size
.desktop-view {
  display: none;

  @include mq('large') {
    display: block;
  }
}

// visible on tablet size & larger
.tablet-desktop-view {
  display: none;

  @include mq('medium') {
    display: block;
  }
}

// visible on tablet size & smaller
.tablet-mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }
}

.mobile-view {
  display: block;

  @include mq('medium') {
    display: none;
  }
}

// padding adjustments
.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

// content related util classes. Used to center buttons mostly.
.content--centered {
  text-align: center;
}

.container--default {
  padding-left: 10px;
  padding-right: 10px;
  max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
  margin-left: auto;
  margin-right: auto;

  @include mq('large') {
    padding-left: 74px;
    padding-right: 74px;
  }
}

[data-component='title'],
[data-component='text'] {
  &.text--color-brown-500 {
    color: var(--emu-common-colors-dark-brown-500);
  }

  &.text--color-white {
    color: var(--emu-common-colors-white);
  }

  &.text--color-black {
    color: var(--emu-common-colors-black);
  }

  &.text--color-tertiary-50 {
    color: var(--emu-semantic-colors-tertiary-50);
  }
}

.bg--light-beige-500 {
  background-color: var(--emu-common-colors-beige-500);
}

.bg--light-brown-500 {
  background-color: var(--emu-common-colors-light-brown-500);
}

.bg--medium-brown-500 {
  background-color: var(--emu-common-colors-medium-brown-500);
}

.bg--dark-brown-500 {
  background-color: var(--emu-common-colors-dark-brown-500);
}

.bg--white {
  background-color: var(--emu-common-colors-white);
}

.bg--primary-200 {
  background-color: var(--emu-semantic-colors-primary-200);
}

.custom-list-type {
  li {
    font-size: var(--emu-semantic-font-sizes-narrow-small);
    line-height: var(--emu-semantic-line-heights-narrow-small);
    color: var(--emu-semantic-colors-tertiary-50);
    margin-bottom: var(--emu-common-spacing-small);
    position: relative;
    list-style: none;
    padding-left: 10px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      background-color: var(--emu-common-colors-dark-brown-500);
      top: 12px;
      left: 0;
      width: 2px;
      height: 2px;
    }
  }
}

// hides overflow in the mobile devices
.mobile-hide-overflow {
  @include mq('large', 'max-width') {
    overflow: hidden;
  }
}

.prm-title {
  .cmp-title__text {
    margin-top: 56px; // actual is 60, but adjusting to match the heights in the design
    margin-bottom: 28px;
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;

    @include mq('large') {
      margin-top: 114px; // actual is 120, but adjusting to match the heights in the design
      margin-bottom: 49px; // actual is 60, but adjusting to match the heights in the design
      max-width: none;
    }

    p {
      font-size: inherit;
      line-height: inherit;
      font-family: inherit;
      color: inherit;
      letter-spacing: inherit;
      font-weight: inherit;
    }

    sup {
      font-weight: var(--emu-common-font-weight-bold);
    }

    u {
      display: inline-block;
      text-decoration: none;
    }
  }

  &--long {
    .cmp-title__text {
      margin-bottom: 42px;

      @include mq('large') {
        margin-bottom: 60px; // actual is 70, but adjusting to match the heights in the design
      }
    }
  }
}
