#home {
  .prm-trusted {
    &-cards-wrapper {
      gap: 10px;

      @include mq('xx-large') {
        gap: 30px;
      }

      > .container {
        width: 354px;
        max-width: 100%;

        @include mq('large') {
          width: 410px;
        }

        @include mq('xx-large') {
          width: min(410px, calc(33.33% - 20px));
        }
      }

      .prm-trusted-card--front img {
        @include mq('large') {
          width: 100%;
        }

        @include mq('x-large') {
          width: 410px; //As per figma design
        }
      }

      .flippable-card {
        &__side {
          &--front {
            @include mq('375px', 'max-width') {
              position: absolute;
            }

            .image,
            .emu-image,
            img {
              width: 100%;
              aspect-ratio: 354 / 443;

              @include mq('large') {
                aspect-ratio: 410 / 513;
              }
            }

            img {
              object-fit: cover;
              object-position: center;
            }
          }

          &--back {
            position: absolute;
            overflow-y: auto;

            @include mq('375px', 'max-width') {
              position: relative;
              min-height: 440px;
            }
          }
        }
      }
    }

    &-card--back {
      background-color: var(--emu-common-colors-light-brown-50);
      padding-top: 22px;
      padding-right: 33px;
      padding-bottom: 26px;
      padding-left: 33px;
      height: 100%;

      @include mq('large') {
        padding-top: 35px;
        padding-bottom: 30px;
      }

      > .image {
        margin-bottom: 26px;
      }

      .cmp-title__text {
        font-size: 18px;
        line-height: var(--emu-semantic-line-heights-narrow-small);
        font-family: var(--emu-semantic-font-families-beatrice-regular);
        font-weight: var(--emu-semantic-font-weight-500);
        color: var(--emu-common-colors-dark-brown-500);
        margin-bottom: 20px;

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-small);
          line-height: var(--emu-semantic-line-heights-narrow-large);
          margin-bottom: 28px;
        }
      }

      .aaaem-text {
        ul {
          padding: var(--emu-common-spacing-none);
          margin: var(--emu-common-spacing-none);
        }

        li {
          font-size: var(--emu-common-font-sizes-wide-medium);
          line-height: 20px;
          color: #4b4c4e; // As per the design. used only once
          margin-bottom: var(--emu-common-spacing-small);
          position: relative;
          list-style: none;
          padding-left: 10px;

          @include mq('large') {
            font-size: 18px;
            line-height: 22px;
          }

          &::before {
            top: 9px;
          }

          li {
            padding-left: var(--emu-common-spacing-medium);
            margin-top: var(--emu-common-spacing-small);

            &::before {
              width: 4px;
              height: 2px;
              left: 4px;
            }
          }
        }
      }

      .button {
        margin-top: auto;

        .emu-button {
          font-size: 18px;
          line-height: var(--emu-semantic-line-heights-narrow-small);
          color: var(--emu-common-colors-medium-brown-500);
          display: flex;
          position: relative;
          text-decoration: none;

          @include mq('large') {
            font-size: 20px;
          }

          &:hover {
            color: var(--emu-common-colors-medium-brown-500);
          }

          .cmp-button__text {
            text-decoration: underline;
            text-underline-offset: 3px;
          }

          // needed to increase specificity
          &.add-icon-right.emu-button {
            svg {
              width: 20px;
              height: 20px;
              margin-left: 17px;
              margin-top: var(--emu-common-spacing-none);
              margin-bottom: var(--emu-common-spacing-none);
              margin-right: var(--emu-common-spacing-none);
              position: relative;
              top: -4px;

              @include mq('large') {
                width: 24px;
                height: 24px;
              }
            }
          }
        }
      }
    }

    &-cards__disclaimer {
      margin-top: 40px;
      margin-bottom: 30px;

      p {
        color: #4b4c4e; // used only once
        font-size: 18px;
        line-height: 25px;
        text-indent: -5px;
        margin-left: 5px;
      }
    }
  }
}
