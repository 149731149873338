html {
  -webkit-text-size-adjust: 100%;
}

// Commenting out, makes fonts less bold
// :root {
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-rendering: optimizeLegibility;
// }

:root {
  --scroll-padding: 90px;
}

html,
body {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  font-family: var(--emu-semantic-font-families-body);
  line-height: 1.4;
  text-align: left;
  scroll-padding-top: 185px;

  @include mq('large') {
    scroll-padding-top: var(--scroll-padding);
  }

  // Adobe adds a negative margin, need to overwrite. Do not remove.
  .xf-content-height {
    margin: inherit;
  }

  .experiencefragment {
    padding: var(--emu-common-spacing-none);
  }
}

body {
  -webkit-overflow-scrolling: touch;
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*,
::before,
::after {
  box-sizing: border-box;
}

.cmp-image__image,
image {
  max-width: 100%;
  width: auto;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

#maincontent.aaaem-container {
  max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
}
