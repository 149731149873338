#home {
  .value-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: var(--emu-common-border-width-thin) solid
      var(--emu-common-colors-medium-brown-500);
    padding-top: 41px;
    padding-bottom: 41px; //to match design
    padding-left: var(--emu-common-spacing-large);
    padding-right: var(--emu-common-spacing-large);

    &.aaaem-container.cmp-container {
      margin-top: 30px;

      @include mq('large') {
        margin-top: 40px;
      }
    }

    @include mq('large') {
      padding-top: 75px; // to match design
      padding-bottom: 76px; // to match design
      padding-left: 202px;
      padding-right: 203px;
    }

    &__image {
      img {
        min-width: 66px;
        max-height: 57px;

        @include mq('large') {
          max-height: none;
          max-width: 66px;
        }
      }
    }

    h5 {
      margin-top: 27px;
      margin-right: 10px;
      margin-bottom: 34px;
      margin-left: 10px;
      font-family: var(--emu-semantic-font-families-beatrice-regular);

      @include mq('large') {
        margin-top: 51px;
        margin-right: var(--emu-common-spacing-none);
        margin-bottom: 39px;
        margin-left: var(--emu-common-spacing-none);
        margin-right: 10px;
        font-family: var(--emu-semantic-font-families-beatrice-semibold);
      }

      .emphasis {
        color: var(--emu-common-colors-light-brown-500);
      }
    }

    p {
      font-family: var(--emu-semantic-font-families-heading);
      line-height: normal;
    }
  }
}
