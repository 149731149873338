.support-container {
  &__body-container {
    display: flex;
    flex-direction: column;
    height: max-content;

    @include mq('large') {
      flex-direction: row;
    }

    & > .container {
      width: 100%;
      margin-bottom: 10px;

      @include mq('large') {
        margin-right: 26px;
        min-width: calc(33.33% - 17.33px);
        margin-bottom: var(--emu-common-spacing-none);
      }

      &:last-child {
        margin-bottom: var(--emu-common-spacing-none);

        @include mq('large') {
          margin-right: var(--emu-common-spacing-none);
        }
      }
    }

    &-individual-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 43px;
      padding-right: 44px;
      padding-top: 34px;
      padding-bottom: 30px;
      height: 100%;
      border: var(--emu-common-border-width-thin) solid
        var(--emu-semantic-colors-secondary-100);

      @include mq('large') {
        padding-left: 33px;
        padding-right: 33px;
        padding-top: 36px;
        padding-bottom: 36px;
      }

      .image {
        max-width: 50px;
      }

      .title {
        margin-top: 30px;
        margin-bottom: 30px;

        @include mq('large') {
          min-height: 160px;
          margin-top: 20px;
          margin-bottom: 44px;
        }
      }

      .custom-list-type {
        ul {
          margin: var(--emu-common-spacing-none);
          padding: var(--emu-common-spacing-none);
        }
        li {
          margin-bottom: 13px;

          a {
            color: inherit;
            text-decoration: none;
          }
        }
      }
    }
  }
}
