[data-component='container-isi'] {
  max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
  margin-left: auto;
  margin-right: auto;

  .aaaem-isi {
    &-container {
      &__banner-title b {
        font-weight: var(--emu-common-font-weight-regular);
      }
    }

    &-banner {
      padding-top: 11px;
      padding-right: 10px;
      padding-bottom: 11px;
      padding-left: 10px;

      @include mq('large') {
        padding-right: var(--emu-common-spacing-xl);
        padding-left: 86px;
      }
      max-width: calc(var(--emu-common-spacing-xl) + 86px + var(--emu-semantic-sizing-breakpoints-xx-large));
      margin-left: auto;
      margin-right: auto;

      p {
        font-size: 16px;
        line-height: 21px;
        font-family: var(--emu-semantic-font-families-mono);
        color: var(--emu-common-colors-dark-brown-500);
      }

      .isi-banner__banner-content {
        button {
          position: relative;
          display: flex;
          align-items: center;
          column-gap: 10px;
          font-family: var(--emu-semantic-font-families-mono);
          font-size: var(--emu-common-font-sizes-wide-medium);
          line-height: normal;
          text-align: right;

          img {
            flex-shrink: 0;
            width: auto;
            height: auto;
            margin-left: var(--emu-common-spacing-none);
          }
        }
      }
    }

    &-container__isi-content {
      padding-top: 40px;
      padding-right: 10px;
      padding-bottom: 38px;
      padding-left: 10px;

      @include mq('large') {
        padding-top: 100px;
        padding-right: 75px;
        padding-bottom: 110px;
        padding-left: 75px;
      }

      .aaaem-text {
        p,
        h5,
        li {
          font-size: 16px;
          line-height: var(--emu-common-line-heights-narrow-medium);
          font-family: var(--emu-semantic-font-families-mono);
          margin-bottom: 10px;

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-small);
            line-height: 23px;
            margin-bottom: 20px;
          }

          a {
            color: inherit;
          }

          sup {
            font-size: 9.6px; // to match the design.

            @include mq('large') {
              font-size: 12px;
            }
          }
        }

        h5 {
          @include mq('large') {
            font-size: 22px;
          }
        }
      }

      .prm__isi {
        &-box-section,
        &-last-section {
          ul {
            margin: var(--emu-common-spacing-none);
            padding: var(--emu-common-spacing-none);

            li {
              text-indent: -10px;
              padding-left: 10px;
              list-style-type: none;
              margin-bottom: 12px;
            }
          }
        }

        &-box-section {
          padding-top: 18px;
          padding-right: 13px;
          padding-bottom: 24px;
          padding-left: 16px;
          border-width: var(--emu-common-border-width-thin);
          border-style: solid;
          border-color: var(--emu-common-colors-black);
          margin-top: 14px;
          margin-bottom: 12px;

          @include mq('large') {
            padding-top: 51px;
            padding-right: 40px;
            padding-bottom: 40px;
            padding-left: 45px;
            margin-top: 40px;
            margin-bottom: 40px;
          }

          p {
            @include mq('large') {
              margin-bottom: var(--emu-common-spacing-none);
            }
          }

          ul {
            margin-top: 12px;

            li {
              margin-bottom: 11px;
            }
          }
        }

        &-last-section {
          p,
          li {
            margin-right: 2px; // to match the design.
          }

          p {
            margin-bottom: 12px;

            .emphasis {
              display: inline-block;
            }
          }
        }
      }
    }

    &-container__banner-title {
      display: block !important; // Overriding default inline styles.
    }
  }

  .isi-container {
    .aaaem-isi-container__isi-content {
      padding: var(--emu-common-spacing-none);
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 40px;
      padding-left: 10px;
      height: 118px; // to match the design.

      @include mq('large') {
        padding-top: 20px;
        padding-right: 75px;
        padding-left: 75px;
        height: 153px;
      }

      &.js-expand {
        height: 156px; // to match the design.

        @include mq('large') {
          height: 270px;
        }
      }
    }
  }
}

.cmp-experiencefragment--isi > div {
  max-width: 100%;
  background-color: var(--emu-common-colors-light-brown-50);
}
