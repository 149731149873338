@font-face {
  font-family: 'Beatrice-Regular';
  src: url('./assets/fonts/Beatrice/Beatrice-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Beatrice-Light';
  src: url('./assets/fonts/Beatrice/Beatrice-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Beatrice-Semibold';
  src: url('./assets/fonts/Beatrice/Beatrice-Semibold.woff2') format('woff2');
}
