.prm__banner-container {
  &-text p {
    color: var(--emu-common-colors-white);
    font-size: 16px;
    line-height: var(--emu-semantic-line-heights-narrow-xl);

    @include mq('large') {
      font-size: 22px;
      padding-top: var(--emu-common-spacing-xs);
      padding-bottom: var(--emu-common-spacing-xs);
    }
  }

  .prm__teaser-container {
    .teaser-container {
      display: flex;
      flex-direction: column;
      max-height: 755px; //as per figma design

      @include mq('large') {
        flex-direction: row;
        align-items: center;
        max-height: 696px; //as per figma design
        padding-left: 40px;
      }

      .teaser-image-container {
        @include mq('large') {
          flex: 1;
        }

        @include mq('xx-large') {
          flex: unset;
        }
      }

      .aaaem-teaser__image {
        padding-left: 23px;
        padding-right: 37px;

        @include mq('large') {
          flex: 1;
          max-width: 568px;
          padding: var(--emu-common-spacing-none);
          margin-right: 34px;
        }

        img {
          margin-top: 59px;
          object-fit: contain;
          margin-left: auto;
          margin-right: auto;
          display: block;
          width: 290px; // as per figma

          @include mq('large') {
            width: auto;
            margin-top: auto;
          }
        }
      }

      .content-container {
        padding-top: 67px;
        padding-right: 10px;
        padding-left: 10px;
        padding-bottom: 31px;
        width: 100%;

        @include mq('large') {
          padding-top: 154px;
          padding-right: 91px;
          padding-left: var(--emu-common-spacing-none);
          padding-bottom: var(--emu-common-spacing-none);
          flex: 1;
        }

        .title-lockup {
          max-width: 100%;
        }
      }

      h2.cmp-teaser__title {
        color: var(--emu-common-colors-dark-brown-500);

        @include mq('large') {
          font: var(--emu-component-ingredients-title-h2-typography-wide);
        }
      }

      .cmp-teaser__description {
        padding-top: var(--emu-common-spacing-xs);
        padding-bottom: 10px;

        @include mq('large') {
          padding-top: 22px;
        }

        p {
          font-size: 18px;
          line-height: 24px;
          color: var(--emu-common-colors-dark-brown-500);
          margin-bottom: var(--emu-common-spacing-small);

          @include mq('large') {
            font-size: 28px;
            line-height: 34px;
          }
        }

        sup {
          font-size: 65%;
        }
      }

      .disclaimer {
        @include mq('large') {
          padding-bottom: 86px;
        }

        p {
          font-size: var(--emu-common-font-sizes-narrow-medium);
          line-height: 16px;
          text-indent: -5px;
          font-weight: 350; //no figma token for this font weight
        }

        sup {
          font-size: 100%;
          top: 4px;
        }
      }
    }

    .prm__teaser-title--spacing {
      .cmp-teaser__title br {
        display: none;

        @include mq('large') {
          display: block;
        }
      }
    }

    &--dark {
      .teaser-container {
        @include mq('large') {
          padding-left: var(--emu-common-spacing-none);
        }

        .teaser-image-container {
          width: 100%;

          .aaaem-teaser__image {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);

            @include mq('large') {
              max-width: none;
              margin-right: var(--emu-common-spacing-none);
            }

            img {
              object-fit: cover;
              height: 755px; //as per figma design
              max-height: 100%;
              margin-top: var(--emu-common-spacing-none);
              width: 100%;
              object-fit: cover;

              @include mq('large') {
                width: 100%;
                max-width: none;
                height: 696px; //as per figma design
              }
            }

            &::after {
              content: '';
              position: absolute;
              display: block;
              background: linear-gradient(
                63.78deg,
                rgba(73, 39, 40, 0.7) -6.7%,
                rgba(7, 29, 73, 0.7) 11.36%,
                rgba(255, 255, 255, 0) 75.4%
              );
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
            }
          }
        }

        .content-container {
          position: absolute;
          height: auto;
          top: 416px;
          display: block;
          padding-top: var(--emu-common-spacing-none);

          @include mq('large') {
            padding-top: 190px;
            padding-left: 76px;
            top: 0;
          }

          &.text-theme-light {
            .cmp-teaser__title {
              color: var(--emu-common-colors-white);
              line-height: var(--emu-semantic-line-heights-narrow-xxl);

              @include mq('large') {
                font-size: var(--emu-semantic-font-sizes-wide-xxl);
                line-height: var(--emu-semantic-line-heights-wide-xxl);
              }
            }

            .cmp-teaser__description {
              padding-top: 20px;
              padding-bottom: var(--emu-common-spacing-none);

              @include mq('x-large') {
                padding-top: 19px;
                max-width: 67%;
              }

              p {
                color: var(--emu-common-colors-white);
                padding-bottom: var(--emu-common-spacing-none);
                display: block !important; // to override display: inline coming by default
              }
            }

            .disclaimer {
              margin-top: 6px;
              padding-bottom: var(--emu-common-spacing-none);
              max-width: 97%;

              @include mq('x-large') {
                max-width: 59%;
                margin-top: 13px;
              }
            }
          }
        }
      }
    }

    &--bold-in-block {
      .teaser-container
        .content-container.text-theme-light
        .cmp-teaser__description
        p
        b {
        @include mq('large') {
          display: block;
        }
      }
    }
  }
}
