.prm-accountability {
  &-container {
    padding-bottom: 40px;

    @include mq('large') {
      padding-bottom: 120px;
    }

    > .container {
      margin-bottom: 10px;

      @include mq('large') {
        margin-bottom: 60px;
      }

      &:last-child {
        @include mq('large') {
          margin-bottom: 30px;
        }
      }
    }
    .cmp-title__text {
      @include mq('large') {
        line-height: var(--emu-common-line-heights-wide-xl);
      }
    }
  }

  &-item {
    border: var(--emu-common-border-width-thin) solid
      var(--emu-semantic-colors-secondary-100);
  }

  &-img-wrapper {
    background-color: var(--emu-common-colors-dark-brown-500);
    padding-top: 55px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 44px;
    min-height: var(--emu-common-sizing-xxl); // As per figma design
    display: flex;
    align-items: center;

    @include mq('large') {
      position: relative;
      padding: var(--emu-common-spacing-none);
      min-height: var(--emu-common-sizing-none);

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90.02deg,
          #492728 0.03%,
          #925c54 52.24%,
          rgba(202, 154, 142, 0.62) 66.21%,
          rgba(242, 217, 208, 0) 80.33%
        );
        z-index: 1; // no variable for z-index 1
      }
    }

    img {
      display: none;

      @include mq('large') {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: var(--emu-common-other-z-index-base);
        display: block;
        object-fit: cover;
      }
    }

    > .container {
      @include mq('large') {
        position: relative;
        z-index: var(--emu-common-other-z-index-layer);
        min-height: 200px;
        align-items: center;
        justify-content: center;
        display: flex;
        padding-left: 80px;
        padding-top: 20px;
        padding-bottom: 20px;
        max-width: 562px;
      }

      .cmp-title__text {
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        line-height: var(--emu-semantic-line-heights-narrow-large);
        font-weight: var(--emu-semantic-font-weight-500);
        color: var(--emu-common-colors-white);
        text-align: left;

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-medium);
          line-height: 44px;
        }
      }

      .aaaem-text {
        margin-top: 20px;

        @include mq('large') {
          margin-top: 12px;
        }
        p {
          font-size: var(--emu-semantic-font-sizes-narrow-small);
          line-height: var(--emu-semantic-line-heights-narrow-small);
          font-weight: var(--emu-semantic-font-weight-500);
          color: var(--emu-common-colors-white);

          .emphasis {
            @include mq('large') {
              display: block;
            }
          }
        }
      }
    }
  }

  &-text-wrapper {
    padding-top: 30px;
    padding-left: 30px;
    padding-bottom: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;

    @include mq('large') {
      flex-direction: row;
      padding-top: 60px;
      padding-left: 60px;
      padding-bottom: 50px;
      padding-right: 60px;
      gap: 20px;
    }

    ul {
      padding: var(--emu-common-spacing-none);
      margin: var(--emu-common-spacing-none);
    }

    > .text {
      flex: 1;
    }
  }
}
