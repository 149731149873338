.aaaem-accordion {
  &__header {
    padding-left: 11px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1); // As per the design.

    @include mq('large') {
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 76px;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: var(--emu-semantic-font-weight-regular);
    line-height: 25px;
    font-family: var(--emu-semantic-font-families-body);
    flex-grow: unset;

    @include mq('large') {
      font-size: 18px;
    }
  }

  &__panel {
    .js-badger-accordion-panel-inner {
      @include mq('large') {
        padding-top: 40px;
        padding-right: 76px;
        padding-bottom: 36px;
        padding-left: 76px;
      }

      .aaaem-text {
        p {
          font-size: 14px;
          line-height: var(--emu-common-line-heights-narrow-medium);
          color: var(--emu-common-colors-black);

          @include mq('large') {
            font-size: 18px;
            line-height: 25px;
          }

          a {
            color: inherit;
            word-break: break-all;
          }
        }
      }
    }
  }
}
